<template>
  <div v-if="mobileWidth" class="index-mobile" >
    <mobile />
  </div>
  <div v-else-if="padWidth" class="index-pad">
    <pad />
  </div>
  <div v-else class="index-pc">
    <pc />
  </div>
</template>

<script>
import mobile from "./basic-mobile";
import pc from "./basic-pc";
import pad from "./basic-pad";
export default {
  computed: {},
  components: {
    mobile,
    pc,pad
  },
  data() {
    return {
      mobileWidth: window.innerWidth < this.$mobileWidth ,
      padWidth: window.innerWidth >= this.$mobileWidth && window.innerWidth <= this.$padWidth,
      currentWidth: window.innerWidth
    };
  },
  created() {},
  mounted() {},
  methods: {},
  filters: {}
};
</script>
