<template>
  <div class="content">
    <Row :gutter="50">
      <i-col span="24">
<!--        <div class="profile-title">My Profile</div>-->

        <Tabs :animated="false">
          <TabPane label="Basic Info" class="23">
            <Form
              ref="form"
              :model="form"
              :rules="rules"
              style="padding-left:5px;width:50%;"
              class="register-form"
            >
              <FormItem label="Email" prop="email">
                <Input v-model="form.email" :readonly="true" />
              </FormItem>
              <FormItem label="First Name" prop="first_name" style="">
                <Input v-model="form.first_name" />
              </FormItem>
              <FormItem label="Last Name" prop="last_name" style="">
                <Input v-model="form.last_name" />
              </FormItem>
              <FormItem label="Company Name" prop="Company" style="">
                <Input v-model="form.company_name" />
              </FormItem>
              <FormItem label="Website" prop="Website" style="">
                <Input v-model="form.website" />
              </FormItem>
              <FormItem label="Phone" prop="Phone">
                <Input v-model="form.phone" />
              </FormItem>
              <div
                class="update-info"
                :loading="editLoading"
                @click="editSubmit"
              >
                Update Information
              </div>
            </Form>
          </TabPane>
          <TabPane label="Change Password">
            <Password></Password>
          </TabPane>
        </Tabs>
      </i-col>

      <i-col span="0">
        <div class="avatar">
          <div @click="modal = true" class="edit">Edit</div>
          <img alt="" v-if="avatar" :src="avatar" />
        </div>

        <Modal
          v-model="modal"
          title="Change Avatar"
          footer-hide
          :mask-closable="false"
        >
          <Alert show-icon>
            We recommend that you upload a photo with a minimum dimension of 400
            x 400.
          </Alert>

          <Cropper
            :stencil-props="{
              aspectRatio: 1
            }"
            classname="cropper"
            :src="image"
            ref="cropper"
          />

          <Button class="mr-20" @click="$refs.file.click()">
            <input
              class="hide"
              type="file"
              ref="file"
              @change="uploadImage($event)"
              accept="image/*"
            />
            Choose image
          </Button>

          <Button
            :loading="uplodLoading"
            :disabled="!image"
            @click="crop"
            type="primary"
          >
            Upload
          </Button>
        </Modal>
      </i-col>
    </Row>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Password from "@/views/user-profile/advance";
import { Cropper } from "vue-advanced-cropper";
import { dataURLtoBlob, blobToFile } from "@/utils/helper";
import { mapState } from "vuex";
import S3 from "aws-s3";
// import store from "@/store";
import api from "@/api";
const { _editMe, _queryMe } = api;

const uploader = new S3({
  bucketName: "connexima",
  dirName: "images",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  components: {
    Cropper,
    Password
  },
  computed: mapState("user", ["avatar"]),

  created() {
    _queryMe().then(response => {
      const { data } = response;
      this.form.first_name = data.first_name;
      this.form.last_name = data.last_name;
      this.form.phone = data.phone;
      // if (user.timezone) this.form.timezone = user.timezone;
      // if (user.availability_time)
      //   this.form.availability_time = user.availability_time;
      this.form.email = data.email;
      this.form.company_name = data.company.company_name;
      // this.form.country = user.country;
      this.form.website = data.company.website;
    });
  },
  data() {
    return {
      image: null,
      modal: false,
      uplodLoading: false,
      editLoading: false,
      timezones: [],
      timezoneLists: moment.tz.names(),
      weeks: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        timezone: moment.tz.guess(),
        availability_time: {
          week: ["Monday", "Sunday"],
          time: ["12:00", "18:00"]
        },
        company_name: "",
        website: ""
      },
      rules: {
        first_name: [
          {
            required: true,
            message: "Please fill"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please fill"
          }
        ]
      }
    };
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      const base64Data = canvas.toDataURL();
      const blob = dataURLtoBlob(base64Data);
      const file = blobToFile(blob);

      this.uplodLoading = true;
      uploader
        .uploadFile(file)
        .then(data => {
          this.$store.commit("user/common", {
            avatar: data.location
          });
          this.updateAvatar(data.location);
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
        });
    },

    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    // getTimezone() {
    //   _queryTimezone().then(res => {
    //     this.timezones = res.data;
    //   });
    // },
    updateAvatar(user_avatar) {
      _editMe({
        ...this.form,
        user_avatar
      })
        .then(() => {
          this.modal = false;
          this.$Message.success("success");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    editSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editLoading = true;
          _editMe({ ...this.form })
            .then(() => {
              this.editLoading = false;
              this.$Message.success("success");
              // refresh
              // can also use store.dispatch("user/queryMe") but its not necessary
              this.$store.commit("user/common", {
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                phone: this.form.phone,
                website: this.form.website,
                full_name: this.form.first_name + ' ' + this.form.last_name,
              });
            })
            .catch(({ message }) => {
              this.editLoading = false;
              this.$Message.error(message);
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  margin: 60px auto 30px auto;
  /*box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;*/
  padding-bottom: 20px;
  /*height: 650px;*/
  /*background-image: url('../../assets/my-profile.png');*/
  padding-left: 10px;
  padding-right: 10px;
}
.profile-title {
  height: 45px;
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  font-weight: 700;
  color: #ffffff;
  line-height: 18px;
  /*padding: 23px 0 0 30px;*/
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.avatar {
  width: 100px;
  height: 100px;
  background: #eee;
  border-radius: 6px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  &:hover {
    .edit {
      opacity: 1;
    }
  }
  .edit {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.44);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    opacity: 0;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.timeSel {
  display: inline-block;
  width: 600px;
  box-sizing: border-box;
  vertical-align: middle;
  /deep/ .sel1 .ivu-select-selection,
  /deep/ .sel1 .ivu-input-with-suffix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  /deep/ .sel2 .ivu-select-selection,
  /deep/ .sel2 .ivu-input-with-suffix {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .tip {
    display: inline-block;
    vertical-align: middle;
    height: 32px;
    line-height: 10px;
    padding: 10px;
    border-top: 1px solid #dcdee2;
    border-bottom: 1px solid #dcdee2;
  }
  .litt {
    padding: 0 10px;
  }
}

.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}

.hide {
  display: none;
}
.update-info {
  width: 220px;
  height: 38px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 18px;

  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  /*padding-top: 9px;*/
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
<style scoped>
.ivu-tabs-bar {
  height: 54px !important;
  margin-bottom: 20px !important;
  position: relative;
  left: 0px !important;
}
.ivu-tabs-nav .ivu-tabs-tab {
  height: 55px !important;
  font-size: 20px !important;

  font-weight: 500;
  /*color: #EF7F21;*/
  line-height: 18px;
  padding-top: 20px !important;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #ef7f21;
}
.ivu-col-span-24 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  height: 36px;
  width: 300px;
}
.register-form >>> .ivu-select-input {
  height: 36px;
  width: 300px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .ivu-form-item {
  margin-bottom: 7px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
</style>
